<template>
  <div>
    <BaseButton
      variant="salmon"
      @click="download()"
    >
      <template slot="iconLeft">
        <Icon
          view-box="0 0 512 512"
          class="w-4 h-4 mr-1"
        >
          <Download />
        </Icon>
      </template>
      Download
    </BaseButton>
    <Modal
      :open="inProgress"
      :close="!inProgress"
    >
      <div class="border-b-2 bg-white">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="font-medium text-lg">
            Generating Candidate Profile PDF...
          </h3>
        </div>
      </div>

      <div class="p-12">
        <Loader />
      </div>
    </Modal>
  </div>
</template>

<script>
import Download from '@components/Icons/Download'
import Icon from '@components/Icons/Icon'
import Loader from '@components/Loader'
import Modal from '@components/Modal'

import exportApi from '@api/export'

export default {
  components: {
    Download,
    Icon,
    Loader,
    Modal
  },

  props: {
    candidate: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      inProgress: false
    }
  },

  computed: {
    /**
     * @return {string}
     */
    candidateName() {
      return this.candidate.firstName + ' ' + this.candidate.surname
    }
  },

  methods: {
    download() {
      this.inProgress = true

      // Account for API change
      let candidateId = this.candidate.id
      if (!candidateId) {
        candidateId = this.candidate.uuid
      }

      return exportApi.candidateProfile(candidateId)
        .then(response => {
          this.createFileDownload(response)
          this.modalOpen = false
        })
        .catch(() => {
          console.error('Cannot download file')
        })
        .finally(() => {
          this.inProgress = false
        })
    },

    /**
     * @param {Object} Axios response
     */
    createFileDownload(response) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', this.candidateName + '.pdf')
      document.body.appendChild(link)
      link.click()
    }
  }
}
</script>
